<template>
  <div>

    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'Banking'">
      <template v-slot:right-end>
      </template>
    </breadcrumb-base>

    <transition name="fade">
      <div class="content-center">
        <div class="vx-col md:w-4/5 w-full mb-base c-center">
          <vx-card :title="$t('UploadBankRecords')">

            <form-wizard ref="wizard"
                         @on-complete="formComplete"
                         shape="circle"
                         color="rgba(var(--vs-primary), 1)" :title="null" :subtitle="null"
                         :finishButtonText="$t('Submit')">
              <wizard-step
                slot-scope="props"
                slot="step"
                :tab="props.tab"
                :transition="props.transition"
                :index="props.index">
              </wizard-step>
              <tab-content title="Step 1" class="mb-5" :before-change="beforeStep1">

                <div class="vx-row flex">
                  <!-- tab 1 content -->
                  <div class="vx-row vx-col ml-3 flex-1">
                    <div class="vx-col c-center c-center-text" v-if="bankingCsv">
                      <feather-icon icon="FileTextIcon" svgClasses="feather-csv" size="25"/>
                      <h1>{{bankingCsv.name}}</h1>
                    </div>
                    <div class="vx-col c-center">
                      <div class="upload-btn-wrapper">
                        <div class="vx-row upload-contents">
                          <feather-icon icon="PlusIcon" svgClasses=""/>
                          <p class="pl-4" style="padding-top: .1rem;">
                            {{$t('UploadCSVFileOnly')}}</p>
                        </div>
                        <input type="file" name="myCsv" accept=".csv,.txt" ref="fileUpload"
                               @change="selectedNewFile"/>
                      </div>
                    </div>
                  </div>

                  <div class="vx-row vx-col ml-3 flex-1" style="place-items: center;">
                    <vs-select autocomplete v-model="delimiter" class="w-full select-large"
                               :label="$t('Delimiter')">
                      <vs-select-item :key="index" :value="item.key" :text="item.title"
                                      v-for="(item,index) in delimiterList" class="w-full"/>
                    </vs-select>
                  </div>
                </div>
              </tab-content>

              <!-- tab 2 content -->
              <tab-content title="Step 2" class="mb-5">
                <div class="vx-row">

                  <div class="vx-col justify-center flex w-full" style="text-align: center;">
                    <div class="flex justify-center">
                      <vx-card class="vs-col md:w-auto" style="display: flex" card-background="danger" content-color="#FFFFFF">
                        <feather-icon icon="XIcon"></feather-icon>
                        <p>{{ $t('Total') }} <b>{{ totalRecordsCount }}</b> {{ $t('Records') }}</p>
                      </vx-card>
                      <div class="px-4"></div>
                      <vx-card class="vs-col md:w-auto" style="display: flex" card-background="success" content-color="#FFFFFF">
                        <feather-icon icon="CheckIcon"></feather-icon>
                        <p>{{ $t('Valid') }} <b>{{ validRecordsCount }}</b> {{ $t('Records') }}</p>
                      </vx-card>
                    </div>
                  </div>
                  <div class="vx-col py-5 justify-center flex w-full" style="text-align: center;" v-if="validRecordsCount <= 0">
                    <h5>{{ $t('NoRecordsToImport') }}</h5>
                  </div>
                </div>
              </tab-content>

              <!--              Custom Footer In Tab-->
              <template slot="footer" slot-scope="props">
                <div class="wizard-footer-left">
                  <!--                  <vs-button v-if="props.activeTabIndex > 0 && !props.isLastStep" @click.native="props.prevTab()" :style="props.fillButtonStyle">Previous</vs-button>-->
                </div>
                <div class="wizard-footer-right">

                  <vs-button v-if="!props.isLastStep" @click.native="props.nextTab()"
                             class="wizard-footer-right" :style="props.fillButtonStyle">
                    {{ $t('Next') }}
                  </vs-button>

                  <vs-button v-if="props.isLastStep && validRecordsCount > 0" @click.native="formComplete"
                             class="wizard-footer-right finish-button"
                             :style="props.fillButtonStyle"> {{props.isLastStep ? $t('Done') : $t('Next')}}
                  </vs-button>
                  <vs-button  @click.native="cancelForm"
                              class="wizard-footer-right finish-button mx-2" color="danger"
                              :style="props.fillButtonStyle"> {{ $t('Cancel') }}
                  </vs-button>

                </div>
              </template>
            </form-wizard>

          </vx-card>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  name: 'BankingUploadFile',
  components: {
    'v-select': vSelect,
  },
  computed: {
    validateStep2() {
      return this.validRecordsCount > 0
    },
    isLastStep() {
      if (this.$refs.wizard) {
        return this.$refs.wizard.isLastStep
      }
      return false
    },
  },
  data() {
    return {
      bankingCsv: null,
      delimiter: ';',
      delimiterList: [
        {
          title: 'Semicolon (;)',
          key: ';',
        },
        {
          title: 'Tab Space',
          key: 't',
        },
        {
          title: 'Colon (:)',
          key: ':',
        },
        {
          title: 'Comma (,)',
          key: ',',
        },
      ],
      validRecordsCount: '',
      totalRecordsCount: '',
      recordsUploadUid: '',
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Banking', i18n: 'Banking', active: true },
      ],
    }
  },
  methods: {
    async selectedNewFile(e) {
      this.bankingCsv = this.$refs.fileUpload.files[0]
    },
    async beforeStep1() {
      if (this.bankingCsv) {
        const payload = {
          bankingCsv: this.bankingCsv,
          delimiter: this.delimiter,
        }
        this.$vs.loading()
        const data = await this.$store.dispatch('banking/uploadBankingCSVFile', payload).catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
          return false
        })
        if (data) {
          this.$vs.loading.close()
          this.validRecordsCount = data.records_count
          this.totalRecordsCount = data.all_records_count
          this.recordsUploadUid = data.uid
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
          return true
        }
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return false
      }
      return false
    },
    cancelForm() {
      this.$router.push({ name: 'banking-list' })
    },
    formComplete() {
      if (this.validateStep2) {
        const payload = {
          uid: this.recordsUploadUid,
          confirm: 1,
        }
        this.$vs.loading()
        this.$store.dispatch('banking/uploadBankingCSVStep2', payload).then((data) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
          this.$router.push({ name: 'banking-list' })
        }).catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
          return false
        })
      }
      return false
    },
  },
  created() {
  },
}
</script>

<style scoped>
.c-center {
  margin: auto;
}

.c-center-text {
  text-align: center;
}

.feather-csv {
  width: 50px;
  height: 50px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.p-type {
  padding: 0 1rem;
  font-size: 10px;
}

.p-type-select {
  padding: 0 0 0 1rem;
}

.upload-btn-wrapper {
  margin: 1rem;
  position: relative;
  display: flex;
}

.upload-contents {
  border: 1px dashed #00000040;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
  color: #2C2C2C;
  background-color: white;
  padding: 30px 30px;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  margin: auto;
}

.upload-btn-wrapper-denomination {
  position: absolute;
}

.upload-contents-denomination {
  padding: 5px;
}

.upload-btn-wrapper input[type=file] {
  position: absolute;
  margin: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.p-image {
  width: 90%;
  object-fit: cover;
  max-height: 200px;
  margin: 1rem;
}

.p-image-denomination {
  width: 50%;
}
</style>
